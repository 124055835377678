import { createAppRoute } from "./helpers/functions/createAppRoute";

/**
 * Contains Routes of Application
 */
export const AppUriPath = (() => {
    const basePath = "/spa/";
    const widgetsPath = "/widgets";
    return {
        signIn: createAppRoute(`${basePath}login`),
        signUp: createAppRoute(`${basePath}signup`),
        subscriptions: createAppRoute(`${basePath}subscriptions`),
        subscriptionSetup: createAppRoute(`${basePath}subscription-setup`),
        onboarding: createAppRoute(`${basePath}subscription-setup/onboarding`),
        invoices: createAppRoute(`${basePath}invoices`),
        account: createAppRoute(`${basePath}account`),
        addons: createAppRoute(`${basePath}addons`),
        plans: createAppRoute(`${basePath}plans`),
        charges: createAppRoute(`${basePath}charges`),
        createSinglePayment: createAppRoute(`${basePath}single-payment`),
        notfound: createAppRoute(`${basePath}notfound`),
        checkout: createAppRoute(`${basePath}checkout`),
        translations: createAppRoute(`${basePath}lexems`),
        session: createAppRoute(`${basePath}sessions/authorize`),
        widgets: createAppRoute(`${widgetsPath}`),
        widgets_invoiceCard: createAppRoute(`${widgetsPath}/invoice-card`),
    };
})();

/**
 * Contains Routes of Application
 */
// export const AppUriPath = (() => ({
//     signIn: createAppRoute('/login'),
//     signUp: createAppRoute('/signup'),
//     subscriptions: createAppRoute('/subscriptions'),
//     subscriptionSetup: createAppRoute('/subscription-setup'),
//     onboarding: createAppRoute('/subscription-setup/onboarding'),
//     invoices: createAppRoute('/invoices'),
//     account: createAppRoute('/account'),
//     addons: createAppRoute('/addons'),
//     plans: createAppRoute('/plans'),
//     charges: createAppRoute('/charges'),
//     createSinglePayment: createAppRoute('/single-payment'),
//     notfound: createAppRoute('/notfound'),
//     checkout: createAppRoute('/checkout'),
//     translations: createAppRoute('/lexems'),
//     session: createAppRoute('/sessions/authorize'),
//     widgets: createAppRoute('/widgets'),
//     widgets_invoiceCard: createAppRoute('/widgets/invoice-card'),
// }))();